export const ONBOARDING_STEPS_METADATA = [
  { id: 'self-description', dataKey: 'selfDescription' },
  { id: 'age', dataKey: 'age' },
  { id: 'gender', dataKey: 'gender' },
  { id: 'zip-code', dataKey: 'zipCode' },
  { id: 'household-people-number', dataKey: 'householdPeopleNumber' },
  { id: 'household-children-number', dataKey: 'householdChildrenNumber' },
  { id: 'allergies-and-intolerances', dataKey: 'allergiesAndIntolerances' },
  {
    id: 'allergies-and-intolerances',
    dataKey: 'allergiesAndIntolerancesOther',
  },
  { id: 'dietary-restrictions', dataKey: 'dietaryRestrictions' },
  { id: 'dietary-restrictions', dataKey: 'dietaryRestrictionsOther' },
  { id: 'eating-help-health-condition', dataKey: 'eatingHelpHealthCondition' },
  { id: 'groceries-shopping-place', dataKey: 'groceriesShoppingPlace' },
  { id: 'groceries-shopping-place', dataKey: 'groceriesShoppingPlaceOther' },
  { id: 'frequently-purchase-products', dataKey: 'frequentlyPurchaseProducts' },
];
