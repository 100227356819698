import { ONBOARDING_STEPS_METADATA } from '~/constants/onboardingStepsMetadata';
import { useOnboardingStore } from '~/stores/onboardingStore';

export const getFullOnboardingDataFromLocal = () => {
  if (!process.client) {
    return;
  }

  const onboardingStore = useOnboardingStore();

  const onboardingProfile = onboardingStore.onboardingData;
  const inviteToken = onboardingStore.onboardingInviteToken;
  const inviteEmail = onboardingStore.onboardingInviteEmail;

  let isCompleted = true;
  let incompleteStepId = null;

  if (!onboardingProfile) {
    isCompleted = false;
    incompleteStepId = ONBOARDING_STEPS_METADATA[0].id;
    return {
      data: null,
      isCompleted: isCompleted,
      incompleteStepId: incompleteStepId,
      inviteToken,
      inviteEmail,
    };
  }

  const data: { [key: string]: string | string[] } = {};

  for (let i = 0; i < ONBOARDING_STEPS_METADATA.length; i++) {
    const onboardingStepKey = ONBOARDING_STEPS_METADATA[i].dataKey;

    const onboardingStepData = onboardingProfile[onboardingStepKey];

    if (onboardingStepData) {
      data[onboardingStepKey] = onboardingStepData;
    }
  }

  const uniqueStepsMetadata = ONBOARDING_STEPS_METADATA.filter(
    (currentItem, index, self) =>
      index === self.findIndex((item) => item.id === currentItem.id)
  );

  for (let i = 0; i < uniqueStepsMetadata.length; i++) {
    const onboardingStepKey = uniqueStepsMetadata[i].dataKey;
    const onboardingStepId = uniqueStepsMetadata[i].id;

    const onboardingStepData = onboardingProfile[onboardingStepKey];

    if (!onboardingStepData) {
      isCompleted = false;
      incompleteStepId = onboardingStepId;
      break;
    }
  }

  return {
    data: data,
    isCompleted: isCompleted,
    incompleteStepId: incompleteStepId,
    inviteToken,
    inviteEmail,
  };
};
